import { axios, API_ROUTES } from "@/config";

export default {
  async getAll(data) {
    let result = await axios.post(
      `${API_ROUTES.listDetailAttributes.save}`,
      data
    );

    return result;
  },
};
